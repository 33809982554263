<template>
  <main>
    <div class="mx-auto max-w-2xl px-6 sm:px-8 py-4">

      <span v-if="loading" class="loading-bg"></span>
      <span v-if="loading" class="loader"></span>

      <nav class="mx-auto flex justify-center gap-x-6 mb-4">
        <div>
          <a :href="`/?booking_code=${$route.query.booking_code}`" @click.prevent="onTicketsLinkClick" class="text-casa-brown hover:text-casa-red">TICKETS</a>
        </div>
        <div>
          <div to="/" class="text-casa-red font-bold">CHECKOUT</div>
          <img class="mx-auto h-7" src="@/assets/images/flower.png"/>
        </div>
      </nav>

      <DiaLog v-model:visible="restartBookingDialogOpen" header="Restart Booking?" :draggable="false">
          <p>
            Are you sure you want to restart your booking?
          </p>
          <div class="flex justify-end gap-4 pt-8">
            <button type="button" @click="restartBookingDialogOpen = false" class="text-center h-10 px-6 rounded-md cursor-pointer border border-casa-red text-casa-red hover:text-white hover:bg-casa-red-light">
              No
            </button>
            <button type="button" @click="restartBooking" class="bg-casa-red hover:bg-casa-red-light text-white text-center h-10 px-6 rounded-md cursor-pointer">
              Yes
            </button>
          </div>
      </DiaLog>

      <DiaLog :visible="timerExpired" modal header="This reservation hold has expired" :draggable="false" :closable="false">
          <p>
            Please re-start your booking and be sure to complete it within 5 minutes.
          </p>
          <div class="flex justify-end gap-4 pt-8">
            <button type="button" @click="restartBooking" class="bg-casa-red hover:bg-casa-red-light text-white text-center h-10 px-6 rounded-md cursor-pointer">
              Re-start Booking
            </button>
          </div>
      </DiaLog>

      <DiaLog :visible="paymentWarning" modal header="Payment failed" :draggable="false" :closable="false">
          <p>
            We were not able to charge your card on file for the difference. Your new date is confirmed but you will be asked for the difference at the restaurant.
          </p>
          <div class="flex justify-end gap-4 pt-8">
            <button type="button" @click="goToConfirmationPage" class="bg-casa-red hover:bg-casa-red-light text-white text-center h-10 px-6 rounded-md cursor-pointer">
              OK
            </button>
          </div>
      </DiaLog>

      <template v-if="holdDataLoaded && !timerExpired">
        <p v-if="holdData.price_diff" class="mb-4 mt-4 advisory-message">Your selected date and time costs ${{ holdData.price_diff }} more than your original booking. If you confirm, we will charge this amount to your credit card on file.</p>

        <div class="sticky top-0 bg-casa-beige mt-2 w-full z-20">
          <div class="sticky top-0 px-6 pt-4 pb-2 w-full border-casa-red border border-b-0 rounded-t-md h-[72px]">
            <div class="flex justify-between items-center">
              <h2 class="font-semibold text-md xxs:text-lg text-casa-brown">Ticket Summary</h2>
            </div>
            <p class="uppercase text-casa-brown text-sm xs:text-base">
              <span class="hidden xs:inline">We're holding</span><span class="xs:hidden">Holding</span> this table <span class="hidden xxs:inline">for you </span>for {{ timer }}
            </p>
          </div>
        </div>
        <div class="px-6 py-2 w-full border border-casa-red border-t-0 border-b-0 bg-casa-beige">
          <div class="font-medium text-casa-brown pt-2 pb-4">
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-user"></i>
              </div>
              <div>
                <span>{{ holdData['num_adults'] }} Adults</span>
                <span v-if="holdData['num_kids'] > 0">, {{ holdData['num_kids'] }} Kids (3-12)</span>
                <span v-if="holdData['num_babies'] > 0">, {{ holdData['num_babies'] }} Kids (0-2)</span>
              </div>
            </div>
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-calendar-day"></i>
              </div>
              <div>{{ getDisplayDate(holdData['res_datetime']) }}</div>
            </div>
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-clock"></i>
              </div>
              <div>{{ getDisplayTime(holdData['res_time']) }}</div>
            </div>
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-fork-knife"></i>
              </div>
              <div>{{ holdData['experience_name'] }}</div>
            </div>
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-map-location-dot"></i>
              </div>
              <div>6715 W Colfax Ave, Lakewood, CO 80214</div>
            </div>
          </div>
          <h2 class="font-semibold text-casa-brown text-lg pb-2">Important Dining Information</h2>
          <p class="text-casa-brown py-1 text-sm">
            You may only FLEX a booking twice.
          </p>
          <p class="text-casa-brown py-1 text-sm">
            All Tickets to Casa Bonita are final sale, non-refundable, and non-transferrable.
          </p>
          <p class="text-casa-brown py-1 pb-4 text-sm">
            Tickets must be purchased online and will be emailed to you. One ticket will be issued per party.
          </p>
        </div>
        <div class="sticky top-[72px] bg-casa-beige pb-2 mb-6 w-full border border-casa-red border-t-0 rounded-b-md shadow-md z-10"></div>

        <div v-if="errors['book']" class="pt-4 text-center">
          <p class="text-casa-danger">{{ errors['book'] }}</p>
        </div>

        <div class="mx-auto flex align-center justify-center pt-2">
          <button type="submit" id="submit" @click.prevent="submitForm" class="mx-auto bg-casa-red hover:bg-casa-red-light text-white uppercase font-serif py-3 px-8 rounded">
            Confirm Reschedule{{ holdData.price_diff ? ` - $${holdData.price_diff}` : ''}}
          </button>
        </div>
      </template>
      <div v-else-if="errors['getHold']">
        <p class="text-casa-brown">{{ errors['getHold'] }}</p>
      </div>
    </div>
  </main>
</template>

<script>
  import api from "@/api"

  export default {
    data() {
      return {
        loading: true,
        holdData: {},
        holdDataLoaded: false,
        errors: {},
        formData: {
          reservation_token: this.$route.query.reservation_token,
        },
        timerSecs: 0,
        timerMins: 0,
        timerExpired: false,
        timerIntervalId: null,
        restartBookingDialogOpen: false,
        paymentWarning: null,
      }
    },
    mounted() {
      this.loadCheckout()
      this.$plausible.trackPageview()
    },
    beforeUnmount(){
      clearInterval(this.timerIntervalId)
    },
    computed: {
      timer() {
        return `${this.timerMins}:${this.timerSecs.toString().padStart(2, '0')}`
      },
    },
    methods: {
      onTicketsLinkClick() {
        if (this.timerExpired) {
          this.restartBooking()
        } else {
          this.restartBookingDialogOpen = true
        }
      },
      restartBooking() {
        this.$router.push({ path: '/flex-tickets', query: {booking_id: this.$route.query.booking_id, flex_token: this.$route.query.flex_token}})
      },
      clearErrors() {
        this.errors = {}
      },
      async loadCheckout() {
        this.clearErrors()
        try {
          let resp = await api.post('/flex-get-hold', {reservation_token: this.formData.reservation_token})
          if (resp.status == 'OK') {
            this.holdData = resp
            this.holdDataLoaded = true

            let secondsUntilExpiration = this.getSecondsUntilExpiration(this.holdData.expires_at)

            this.startTimer(secondsUntilExpiration)
            if (this.timerExpired) {
              this.loading = false
              return
            }

            this.loading = false
            // this.$plausible.trackEvent('FlexCheckout-PageLoaded')
          } else {
            this.loading = false
            console.error("/flex-get-hold did not return with status 'OK'")
          }
        } catch (error) {
          this.loading = false
          if (error.status == 'booking-canceled') {
            // booking is canceled
            console.error(error.display_error)
            this.errors['getHold'] = error.display_error
          } else if (error.status == 'error') {
            if (error.token_expired) {
              this.timerExpired = true
            } else {
              console.error(error.display_error)
              this.errors['getHold'] = error.display_error
            }
          } else {
            console.error(error)
          }
        }
      },
      getSecondsUntilExpiration(expirationISODateString) {
        const now = new Date(new Date().toISOString().slice(0, -1)) // TODO KB there has to be a better way to get current UTC time as Date
        const expDate = new Date(expirationISODateString)
        // Calculate the time difference in milliseconds
        const timeDifference = expDate - now
        if (timeDifference < 0) {
          return 0
        }
        // Convert milliseconds to seconds
        const secondsDifference = Math.floor(timeDifference / 1000);

        return secondsDifference
      },
      startTimer(secondsUntilExpiration) {
        let secsMinusBuffer = secondsUntilExpiration - 10
        if (secsMinusBuffer <= 0) {
          this.timerMins = 0
          this.timerSecs = 0
          this.timerExpired = true
          return
        }
        let timerMins = Math.floor(secsMinusBuffer / 60)
        let timerSecs = secsMinusBuffer - (timerMins * 60)
        this.timerMins = timerMins
        this.timerSecs = timerSecs
        this.timerIntervalId = setInterval(this.tickTimer, 1000)
      },
      tickTimer() {
        if (this.timerSecs == 0 && this.timerMins > 0) {
          this.timerSecs = 59
          this.timerMins -= 1
        } else {
          this.timerSecs -= 1
        }
        if (this.timerMins <= 0 && this.timerSecs <= 0) {
          this.timerExpired = true
        }
      },
      getDisplayDate(isoDateString) {
        let date = new Date(isoDateString)
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        return date.toLocaleDateString(undefined, options)
      },
      getDisplayTime(time) {
        let [hours, minutes] = time.split(':')
        let date = new Date()
        date.setHours(hours)
        date.setMinutes(minutes)
        return date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })
      },
      goToConfirmationPage() {
        this.$router.push({ path: '/flex-confirmation', query: { booking_id: this.$route.query.booking_id, reservation_token: this.formData.reservation_token } })
      },
      async submitForm() {
        // Double check that the timer hasn't already expired before submitting
        if (this.getSecondsUntilExpiration(this.holdData.expires_at) <= 0) {
          this.timerExpired = true
          return
        }

        console.log("Submitting form...")
        this.loading = true
        this.clearErrors()

        // this.$plausible.trackEvent('FlexConfirm-Start')
        try {
          let resp = await api.post('/flex-confirm', this.formData)
          if (resp.status == 'OK') {
            console.log("Flexed successfully")
            // this.$plausible.trackEvent('FlexConfirm-Confirmed')
            if (resp.payment_warning) {
              this.loading = false
              this.paymentWarning = resp.payment_warning
            } else {
              this.goToConfirmationPage()
            }
          } else {
            // TODO KB display error
            this.loading = false
            console.error("/flex-confirm did not return with status 'OK'")
          }
        } catch(error) {
          this.loading = false
          if (error.status == 'booking-canceled') {
            // booking is canceled
            this.errors['book'] = error.display_error
            console.error(error.display_error)
          } else if (error.status == 'error') {
            this.errors['book'] = error.display_error
            console.error(error.display_error)
          } else {
            console.error(error)
          }
        }
      },
    }
  }
</script>

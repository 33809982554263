<script setup>
import { RouterLink } from 'vue-router'
</script>

<template>
  <main>
    <div class="mx-auto max-w-2xl px-6 sm:px-8 py-4">

      <span v-if="loading" class="loading-bg"></span>
      <span v-if="loading" class="loader"></span>

      <nav class="mx-auto flex justify-center gap-x-6 mb-4">
        <div>
          <a :href="`/?booking_code=${$route.query.booking_code}`" @click.prevent="onTicketsLinkClick" class="text-casa-brown hover:text-casa-red">TICKETS</a>
        </div>
        <div>
          <div to="/" class="text-casa-red font-bold">CHECKOUT</div>
          <img class="mx-auto h-7" src="@/assets/images/flower.png"/>
        </div>
      </nav>

      <DiaLog v-model:visible="restartBookingDialogOpen" header="Restart Booking?" :draggable="false">
          <p>
            Are you sure you want to restart your booking?
          </p>
          <div class="flex justify-end gap-4 pt-8">
            <button type="button" @click="restartBookingDialogOpen = false" class="text-center h-10 px-6 rounded-md cursor-pointer border border-casa-red text-casa-red hover:text-white hover:bg-casa-red-light">
              No
            </button>
            <button type="button" @click="restartBooking" class="bg-casa-red hover:bg-casa-red-light text-white text-center h-10 px-6 rounded-md cursor-pointer">
              Yes
            </button>
          </div>
      </DiaLog>

      <DiaLog :visible="timerExpired" modal header="This reservation hold has expired" :draggable="false" :closable="false">
          <p>
            Please re-start your booking and be sure to complete it within 5 minutes.
          </p>
          <div class="flex justify-end gap-4 pt-8">
            <button type="button" @click="restartBooking" class="bg-casa-red hover:bg-casa-red-light text-white text-center h-10 px-6 rounded-md cursor-pointer">
              Re-start Booking
            </button>
          </div>
      </DiaLog>

      <template v-if="holdDataLoaded && !timerExpired">
        <div class="sticky top-0 bg-casa-beige mt-2 w-full z-20">
          <div class="sticky top-0 px-6 pt-4 pb-2 w-full border-casa-red border border-b-0 rounded-t-md h-[72px]">
            <div class="flex justify-between items-center">
              <h2 class="font-semibold text-md xxs:text-lg text-casa-brown">Ticket Summary - ${{ holdData.price_data.total }}</h2>
            </div>
            <p class="uppercase text-casa-brown text-sm xs:text-base">
              <span class="hidden xs:inline">We're holding</span><span class="xs:hidden">Holding</span> this table <span class="hidden xxs:inline">for you </span>for {{ timer }}
            </p>
          </div>
        </div>
        <div class="px-6 py-2 w-full border border-casa-red border-t-0 border-b-0 bg-casa-beige">
          <div class="font-medium text-casa-brown pt-2 pb-4">
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-user"></i>
              </div>
              <div>
                <span>{{ holdData['num_adults'] }} Adults</span>
                <span v-if="holdData['num_kids'] > 0">, {{ holdData['num_kids'] }} Kids (3-12)</span>
                <span v-if="holdData['num_babies'] > 0">, {{ holdData['num_babies'] }} Kids (0-2)</span>
              </div>
            </div>
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-calendar-day"></i>
              </div>
              <div>{{ getDisplayDate(holdData['res_datetime']) }}</div>
            </div>
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-clock"></i>
              </div>
              <div>{{ getDisplayTime(holdData['res_time']) }}</div>
            </div>
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-fork-knife"></i>
              </div>
              <div>{{ holdData['experience_name'] }}</div>
            </div>
            <div class="py-1 flex">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-map-location-dot"></i>
              </div>
              <div>6715 W Colfax Ave, Lakewood, CO 80214</div>
            </div>
            <div class="py-1 flex" v-if="holdData.price_data.flex > 0">
              <div class="w-auto h-6 pr-4 text-base">
                <i class="fa-sharp fa-light fa-ticket"></i>
              </div>
              <div>FLEX Ticket</div>
            </div>
          </div>
          <h2 class="font-semibold text-casa-brown text-lg pb-2">Important Dining Information</h2>
          <p class="text-casa-brown py-1 text-sm">
            All Tickets to Casa Bonita are final sale, non-refundable, and non-transferrable.
          </p>
          <p class="text-casa-brown py-1 pb-4 text-sm">
            Tickets must be purchased online and will be emailed to you. One ticket will be issued per party.
          </p>
          <div class="text-casa-brown">
            <div v-for="item in holdData.price_data.line_items" class="flex justify-between py-1" :key="item.sku">
              <div>{{ item.qty}}x {{ item.label }} ({{ item.unit_price }} each)</div>
              <div class="pl-4">${{ item.item_subtotal }}</div>
            </div>
            <div class="flex justify-between py-1">
              <div>Service Charge ({{ holdData.price_data.service_charge_pct }}%) <img @click.prevent="openServiceChargeDialog" class="cursor-pointer inline-block align-top" src="@/assets/images/info.svg"/></div><div>${{ holdData.price_data.service_charge }}</div>
            </div>
            <DiaLog v-model:visible="serviceChargeDialogOpen" header="Service Charge" :draggable="false" style="position: absolute" :style="{ top: serviceChargeDialogTop+'px' }">
                <p>
                  This service charge helps us compensate our team well while ensuring our guests’ experience is without additional fees.
                </p>
            </DiaLog>
            <div class="flex justify-between py-1 pb-6">
              <div>Subtotal</div><div>${{ holdData.price_data.subtotal }}</div>
            </div>
            <div class="flex justify-between py-1">
              <div>PIF</div><div>${{ holdData.price_data.pif }}</div>
            </div>
            <div class="flex justify-between py-1">
              <div>Sales Tax (7.5%)</div><div>${{ holdData.price_data.sales_tax }}</div>
            </div>
            <div v-if="holdData.price_data.flex > 0" class="flex justify-between py-1">
              <div>FLEX Ticket</div><div>${{ holdData.price_data.flex }}</div>
            </div>
            <hr class="border-casa-brown  my-4">
            <div class="font-bold flex justify-between py-1">
              <div>Total</div><div>${{ holdData.price_data.total }}</div>
            </div>
          </div>

          <!-- Flex -->
          <div v-if="holdData.flex_available" class="mx-auto mt-4 ml-0 mr-0 bg-casa-red text-white py-4 px-4 rounded">
            <h2 class="text-lg xs:text-xl font-bold pb-2">
              FLEX Ticket
            </h2>
            <ul class="list-disc ml-6 my-4 text-sm xs:text-base">
              <li> Purchasing a FLEX Ticket allows you to move your reservation to a future date (based on availability).</li>
              <li> You are able to FLEX your ticket two times.</li>
              <li> You are able to FLEX your ticket within our current booking calendar.</li>
              <li> Tickets must be rescheduled 24 hours before the time listed on your original reservation.</li>
              <li> You are not able to modify tickets from Traditional to Cliffside.</li>
              <li> You are not able to add additional people to your reservation.</li>
              <li> All ticket sales are final.</li>
              <li> All tickets are non-refundable.</li>
              <li> All tickets are non-transferrable.</li>
            </ul>

            <div class="py-2">
              <CheckBox v-model="formData.flex_selected" :binary="true" inputId="flex_selected" name="flex_selected" @change="updateFlex"></CheckBox>
              <label for="flex_selected" class="ml-2 text-white font-xl">FLEX Ticket ${{ holdData.flex_price}}/order </label>
            </div>
          </div>

          <div v-if="errors['updateHold']" class="pt-2">
            <p class="text-casa-red">{{ errors['updateHold'] }}</p>
          </div>

        </div>
        <div class="sticky top-[72px] bg-casa-beige pb-2 mb-6 w-full border border-casa-red border-t-0 rounded-b-md shadow-md z-10"></div>

        <h3 class="text-lg text-casa-brown font-semibold">Your Details</h3>
        <p class="text-casa-brown pt-1 pb-2 text-sm">Ticker holder must show ID to enter</p>

        <label for="first_name" class="block mt-2 mb-1 text-casa-brown text-sm">First Name</label>
        <InputText id="first_name" type="text" v-model="formData.first_name" class="w-full mb-1"/>
        <p class="text-sm text-casa-danger" v-if="getBookErrors('first_name')">{{ getBookErrors('first_name') }}</p>

        <label for="last_name" class="block mt-2 mb-1 text-casa-brown text-sm">Last Name</label>
        <InputText id="last_name" type="text" v-model="formData.last_name" class="w-full mb-1"/>
        <p class="text-sm text-casa-danger" v-if="getBookErrors('last_name')">{{ getBookErrors('last_name') }}</p>

        <label for="intlPhoneInput" class="block mt-2 mb-1 text-casa-brown text-sm">Phone</label>
        <input id="intlPhoneInput" ref="intlPhoneInput" name="phone" type="tel" value="" @input="setTelFormData" @countrychange="setTelFormData" class="w-full py-2 border-2 rounded-md border-casa-red bg-transparent text-casa-brown focus:outline-none focus:outline-offset-0 focus:ring focus:ring-casa-red/50 placeholder-casa-brown/50"/>
        <p class="text-sm text-casa-danger" v-if="getBookErrors('phone')">{{ getBookErrors('phone') }}</p>

        <label for="email" class="block mt-2 mb-1 text-casa-brown text-sm">Email</label>
        <InputText id="email" type="email" v-model="formData.email" class="w-full mb-1"/>
        <p class="text-sm text-casa-danger" v-if="getBookErrors('email')">{{ getBookErrors('email') }}</p>


        <h3 class="text-lg text-casa-brown font-semibold pt-4">Billing</h3>

        <!-- Stripe payment form -->
        <form id="payment-form" class="py-4">
          <div id="payment-element">
          </div>
          <div id="payment-message" class="hidden"></div>
        </form>

        <p class="text-xs font-serif text-casa-brown/80 pb-2">
          By providing your card information, you allow Casa Bonita Denver to charge your card for reservation amendments in accordance with their terms.
        </p>
        <p class="text-xs font-serif text-casa-brown/80 pb-4">
          By clicking 'Purchase' you agree to the Casa Bonita <a href="https://www.casabonitadenver.com/terms-and-conditions" target="_blank" class="text-casa-brown-dark">Terms & Conditions</a> and <a href="https://www.casabonitadenver.com/privacy-policy" target="_blank" class="text-casa-brown-dark">Privacy Policy</a>, as well as the OpenTable <a href="https://www.opentable.com/legal/terms-and-conditions" target="_blank" class="text-casa-brown-dark">Terms of Use</a> and <a href="https://www.opentable.com/legal/privacy-policy" target="_blank" class="text-casa-brown-dark">Privacy Policy</a>
        </p>

        <div class="py-2 flex flex-row">
          <CheckBox v-model="formData.marketing_opt_in" :binary="true" inputId="marketing_opt_in" name="marketing_opt_in"></CheckBox>
          <label for="marketing_opt_in" class="ml-4 text-casa-brown text-sm xs:text-base"> Yes, I want to receive dining offers and news from Casa Bonita by email. </label>
        </div>

        <div class="py-2 flex flex-row">
            <CheckBox v-model="formData.text_opt_in" :binary="true" inputId="text_opt_in" name="text_opt_in"></CheckBox>
            <label for="text_opt_in" class="ml-4 text-casa-brown text-sm xs:text-base">Yes, I want to get text updates and reminders about my booking. Message & data rates may apply. You can opt out of receiving text messages at any time by replying STOP.</label>
        </div>

        <div v-if="errors['book']" class="pt-4 text-center">
          <p class="text-casa-danger">{{ errors['book']['display_error'] }}</p>
        </div>
        <div v-if="errors['stripe']" class="pt-4 text-center">
          <p class="text-casa-danger">{{ errors['stripe'] }}</p>
        </div>
        <div v-if="errors['finalize']" class="pt-4 text-center">
          <p class="text-casa-danger">{{ errors['finalize'] }}</p>
        </div>

        <div class="mx-auto flex align-center justify-center pt-8">
          <button type="submit" id="submit" @click.prevent="submitForm" class="mx-auto bg-casa-red hover:bg-casa-red-light text-white uppercase font-serif py-3 px-8 rounded">
            Purchase Tickets - ${{ holdData.price_data.total }}
          </button>
        </div>
      </template>
      <div v-else-if="errors['getHold']">
        <p class="text-casa-brown">{{ errors['getHold'] }}</p>
      </div>
    </div>
  </main>
</template>

<script>
  import api from "@/api"
  import intlTelInput from 'intl-tel-input'
  import intlTelInputUtilsURL from 'intl-tel-input/build/js/utils.js?url'
  import 'intl-tel-input/build/css/intlTelInput.css'

  let stripe
  let elements

  export default {
    data() {
      return {
        loading: true,
        holdData: {},
        bookingCodeGroupName: '',
        holdDataLoaded: false,
        errors: {},
        formData: {
          reservation_token: this.$route.query.reservation_token,
          flex_selected: false,
          first_name: '',
          last_name: '',
          email: '',
          phone_country: '',
          phone: '',
          text_opt_in: true,
          marketing_opt_in: true,
        },
        timerSecs: 0,
        timerMins: 0,
        timerExpired: false,
        timerIntervalId: null,
        finalizeIntervalId: null,
        serviceChargeDialogOpen: false,
        serviceChargeDialogTop: 0,
        restartBookingDialogOpen: false,
        iti: null
      }
    },
    mounted() {
      this.loadCheckout()
      this.$plausible.trackPageview()
    },
    beforeUnmount(){
      clearInterval(this.timerIntervalId)
      clearInterval(this.finalizeIntervalId)
    },
    computed: {
      timer() {
        return `${this.timerMins}:${this.timerSecs.toString().padStart(2, '0')}`
      },
    },
    methods: {
      onTicketsLinkClick() {
        if (this.timerExpired) {
          this.restartBooking()
        } else {
          this.restartBookingDialogOpen = true
        }
      },
      restartBooking() {
        this.$router.push({ path: '/', query: {booking_code: this.$route.query.booking_code}})
      },
      setTelFormData() {
        this.formData.phone_country = this.iti.getSelectedCountryData().iso2
        this.formData.phone = this.iti.getNumber()
      },
      clearErrors() {
        this.errors = {}
      },
      async loadCheckout() {
        this.clearErrors()
        try {
          let resp = await api.post('/get-hold', {reservation_token: this.formData.reservation_token})
          if (resp.status == 'OK') {
            this.holdData = resp
            this.holdDataLoaded = true
            this.bookingCodeGroupName = resp.booking_code_group_name
            this.formData.flex_selected = resp.price_data.is_flex
            this.formData.email = resp.default_email

            let secondsUntilExpiration = this.getSecondsUntilExpiration(this.holdData.expires_at)

            this.startTimer(secondsUntilExpiration)
            if (this.timerExpired) {
              this.loading = false
              return
            }

            // Load Stripe elements
            stripe = window.Stripe(import.meta.env.VITE_STRIPE_PK)
            const appearance = {
              theme: 'stripe',
              variables: {
                colorText: '#985644',
                colorDanger: '#df1b41',
                // fontFamily: 'Poppins'
              },
              rules: {
                '.Input, .Block, .Tab': {
                  backgroundColor: 'transparent',
                  border: '2px solid #B04537'
                },
                '.Button': {
                  backgroundColor: '#B04537'
                },
                // '.Input::placeholder': {
                //   fontFamily: 'Poppins'
                // },
                // '.TermsText, .Label': {
                //   fontFamily: 'Poppins'
                // }
              }
            }
            let clientSecret = resp.pi_client_secret
            elements = stripe.elements({ appearance, clientSecret })
            const paymentElementOptions = {
              layout: "tabs",
              terms: {
                card: "never"
              }
            }
            const paymentElement = elements.create("payment", paymentElementOptions)

            this.$nextTick(() => {
              // mount stripe inputs
              paymentElement.mount("#payment-element")

              // mount international phone input
              const input = this.$refs['intlPhoneInput']
              this.iti = intlTelInput(input, {
                utilsScript: intlTelInputUtilsURL,
                initialCountry: 'us',
                showSelectedDialCode: false
              })
            })

            this.loading = false
            // this.$plausible.trackEvent('Checkout-PageLoaded', {props: {booking_code_group_name: this.bookingCodeGroupName}})
          } else {
            this.loading = false
            console.error("/get-hold did not return with status 'OK'")
          }
        } catch (error) {
          this.loading = false
          if (error.status == 'booking-active') {
            // booking is already confirmed (active)
            this.$router.push({ path: '/confirmation', query: { booking_id: error.booking_id, reservation_token: this.formData.reservation_token } })
          } else if (error.status == 'booking-canceled') {
            // booking is canceled
            console.error(error.display_error)
            this.errors['getHold'] = error.display_error
          } else if (error.status == 'error') {
            if (error.token_expired) {
              this.timerExpired = true
            } else {
              console.error(error.display_error)
              this.errors['getHold'] = error.display_error
            }
          } else {
            console.error(error)
          }
        }
      },
      getSecondsUntilExpiration(expirationISODateString) {
        const now = new Date(new Date().toISOString().slice(0, -1)) // TODO KB there has to be a better way to get current UTC time as Date
        const expDate = new Date(expirationISODateString)
        // Calculate the time difference in milliseconds
        const timeDifference = expDate - now
        if (timeDifference < 0) {
          return 0
        }
        // Convert milliseconds to seconds
        const secondsDifference = Math.floor(timeDifference / 1000);

        return secondsDifference
      },
      startTimer(secondsUntilExpiration) {
        let secsMinusBuffer = secondsUntilExpiration - 10
        if (secsMinusBuffer <= 0) {
          this.timerMins = 0
          this.timerSecs = 0
          this.timerExpired = true
          return
        }
        let timerMins = Math.floor(secsMinusBuffer / 60)
        let timerSecs = secsMinusBuffer - (timerMins * 60)
        this.timerMins = timerMins
        this.timerSecs = timerSecs
        this.timerIntervalId = setInterval(this.tickTimer, 1000)
      },
      tickTimer() {
        if (this.timerSecs == 0 && this.timerMins > 0) {
          this.timerSecs = 59
          this.timerMins -= 1
        } else {
          this.timerSecs -= 1
        }
        if (this.timerMins <= 0 && this.timerSecs <= 0) {
          this.timerExpired = true
        }
      },
      getDisplayDate(isoDateString) {
        let date = new Date(isoDateString)
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        return date.toLocaleDateString(undefined, options)
      },
      getDisplayTime(time) {
        let [hours, minutes] = time.split(':')
        let date = new Date()
        date.setHours(hours)
        date.setMinutes(minutes)
        return date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })
      },
      openServiceChargeDialog(event) {
        this.serviceChargeDialogOpen = true
        this.serviceChargeDialogTop = event.clientY + 16
      },
      getBookErrors(field) {
        return this.errors['book'] && this.errors['book']['errors'] && this.errors['book']['errors'][field]
      },
      async updateFlex() {
        this.loading = true
        this.clearErrors()
        try {
          let resp = await api.post('/update-hold', this.formData)
          this.loading = false
          if (resp.status == 'OK') {
            this.holdData.price_data = resp.price_data
          } else {
            console.error("/update-hold did not return with status 'OK'")
          }
        } catch(error) {
          this.loading = false
          if (error.status == 'error') {
            this.errors['updateHold'] = error.display_error
            console.error(error.display_error)
          } else {
            console.error(error)
          }
        }
      },
      async submitForm() {
        // Double check that the timer hasn't already expired before submitting
        if (this.getSecondsUntilExpiration(this.holdData.expires_at) <= 0) {
          this.timerExpired = true
          return
        }

        // Hit /book, wait for success, and then confirm payment with Stripe
        console.log("Submitting form...")
        this.loading = true
        this.clearErrors()

        const FRONTEND_BASE = import.meta.env.VITE_FRONTEND_BASE
        // this.$plausible.trackEvent('Book-Start', {props: {booking_code_group_name: this.bookingCodeGroupName}})
        try {
          let bookResp = await api.post('/book', this.formData)
          if (bookResp.status == 'OK') {
            console.log("Booked successfully. Confirming payment...")
            // this.$plausible.trackEvent('Book-Booked', {props: {booking_code_group_name: this.bookingCodeGroupName}})
            // NB: the return_url is *mostly* superfluous, since right now at least the backend creates the PaymentIntent
            // to avoid any redirect-based payment methods
            const { error } = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: `${FRONTEND_BASE}/confirmation?booking_code=${this.$route.query.booking_code}&reservation_token=${this.$route.query.reservation_token}`,
              },
              redirect: 'if_required'
            })
            if (error) {
              this.loading = false
              if (error.type === "card_error" || error.type === "validation_error" || error.type === "invalid_request_error") {
                this.errors['stripe'] = error.message
                console.error(error.message)
              } else {
                console.log(error)
                console.error("An unexpected error occurred.")
              }
            } else {
              console.log("Payment processed successfully. Polling /finalize...")
              // this.$plausible.trackEvent('Book-PaymentConfirmed', {props: {booking_code_group_name: this.bookingCodeGroupName}})
              this.pollFinalize()
            }
          } else {
            this.loading = false
            console.error("/book did not return with status 'OK'")
          }
        } catch(error) {
          this.loading = false
          if (error.status == 'error') {
            this.errors['book'] = error
            console.error(error.display_error)
          } else {
            console.error(error)
          }
        }
      },
      pollFinalize() {
        this.finalizeIntervalId = setInterval(this.finalize, 1000)
      },
      async finalize() {
        this.clearErrors()
        try {
          const resp = await api.post('/finalize', {reservation_token: this.formData.reservation_token})
          if (resp.status == 'OK') {
            // this.$plausible.trackEvent('Book-Finalized', {props: {booking_code_group_name: this.bookingCodeGroupName}})
            clearInterval(this.finalizeIntervalId)
            this.$router.push({ path: '/confirmation', query: { booking_id: resp.booking_number, reservation_token: this.formData.reservation_token } })
          }
        } catch (error) {
          this.loading = false
          clearInterval(this.finalizeIntervalId)
          if (error.status == 'error') {
            this.errors['finalize'] = error.display_error
            console.error(error.display_error)
          } else {
            console.error(error)
          }
        }
      },
    }
  }
</script>

<style scoped>
  :deep(.iti) {
    width: 100%;
    margin-bottom: 0.5rem;
  }
</style>

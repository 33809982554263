import global from './global.js';
import calendar from './calendar';
import checkbox from './checkbox';
import inputmask from './inputmask';
import inputnumber from './inputnumber';
import inputtext from './inputtext';
import selectbutton from './selectbutton';
import button from './button';
import dialog from './dialog';
import tooltip from './tooltip';
import toast from './toast';

export default {
    global,
    directives: {
        tooltip,
    },
    calendar,
    checkbox,
    inputmask,
    inputnumber,
    inputtext,
    selectbutton,
    button,
    dialog,
    toast,
}

import Plausible from 'plausible-tracker'

export default {
    install: (app, options) => {
        const plausible = Plausible({
            domain: import.meta.env.VITE_PLAUSIBLE_DOMAIN,
            trackLocalhost: true
        })

        app.config.globalProperties.$plausible = plausible
    }
}
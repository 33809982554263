import { createRouter, createWebHistory } from 'vue-router'
import TicketsView from '../views/TicketsView.vue'
import CheckoutView from '../views/CheckoutView.vue'
import ConfirmationView from '../views/ConfirmationView.vue'
import FlexStartView from '../views/FlexStartView.vue'
import FlexTicketsView from '../views/FlexTicketsView.vue'
import FlexCheckoutView from '../views/FlexCheckoutView.vue'
import FlexConfirmationView from '../views/FlexConfirmationView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'tickets',
      component: TicketsView
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: CheckoutView
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: ConfirmationView
    },
    {
      path: '/flex',
      name: 'flex',
      component: FlexStartView
    },
    {
      path: '/flex-tickets',
      name: 'flex-tickets',
      component: FlexTicketsView
    },
    {
      path: '/flex-checkout',
      name: 'flex-checkout',
      component: FlexCheckoutView
    },
    {
      path: '/flex-confirmation',
      name: 'flex-confirmation',
      component: FlexConfirmationView
    }
  ]
})

export default router

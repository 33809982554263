const API_BASE = import.meta.env.VITE_API_BASE

const handleResponse = (response) => {
    if (response.status > 399) {
        return response.json().catch(() => {
            throw {'__all__': ['An error has occurred.'], '__status__': response.status}
        }).then(err => {
            err.__status__ = response.status
            throw err
        })
    }

    return response.json()
}

const handleError = (err) => {
    if (!Object.keys(err).length) {
        err.__all__ = ['There was a problem communicating with the server.']
    }
    throw err
}

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const get = (url) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    url = `${API_BASE}${url}`
    return fetch(url, {
        method: 'GET',
        headers: headers,
        credentials: 'include',
    }).then(handleResponse).catch(handleError)
}

const post = (url, data) => {
    let csrftoken = getCookie('csrftoken')
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
    }
    url = `${API_BASE}${url}`
    return fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
        credentials: 'include',
    }).then(handleResponse).catch(handleError)
}

export default {
    get,
    post,
    API_BASE,
}
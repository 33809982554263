<template>
  <main>
    <div class="mx-auto max-w-2xl px-6 sm:px-8 py-4">

      <span v-if="loading" class="loading-bg"></span>
      <span v-if="loading" class="loader"></span>

      <nav class="mx-auto flex justify-center gap-x-6">
        <div>
          <div class="text-casa-red font-bold">TICKETS</div>
          <img class="mx-auto h-7" src="@/assets/images/flower.png"/>
        </div>
        <div class="text-casa-brown">CHECKOUT</div>
      </nav>

      <DiaLog v-if="blockingMessage" :visible="true" modal :header="blockingMessage.title" :draggable="false" :closable="false">
          <p v-html="blockingMessage.message"></p>
      </DiaLog>

      <p v-if="advisoryMessage" class="mt-4 advisory-message" v-html="advisoryMessage.message"></p>

      <p v-if="errors['calendar']" class="mt-8 html-error" v-html="errors['calendar']"></p>

      <div v-if="calendarDataLoaded">

      <div class="px-6 py-2 my-4 w-full border border-casa-red rounded-md bg-casa-beige">
        <div class="font-medium text-casa-brown pt-2 pb-4">
          <h2 class="font-semibold text-casa-brown text-lg pb-1">Reschedule Booking</h2>
          <p class="text-casa-brown py-1 text-sm mb-2">
            You may reschedule this booking for the same party size and experience.
          </p>
          <div class="py-1 flex">
            <div class="w-auto h-6 pr-4 text-base">
              <i class="fa-sharp fa-light fa-user"></i>
            </div>
            <div>
              <span>{{ numAdults }} Adults</span>
              <span v-if="numKids > 0">, {{ numKids }} Kids (3-12)</span>
              <span v-if="numBabies > 0">, {{ numBabies }} Kids (0-2)</span>
            </div>
          </div>
          <div class="py-1 flex">
            <div class="w-auto h-6 pr-4 text-base">
              <i class="fa-sharp fa-light fa-fork-knife"></i>
            </div>
            <div>{{ booking['experience_name'] }}</div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 content-between py-2">
        <div class="flex py-1">
          <div class="h-6 w-6 bg-casa-brown-dark"></div><div class="h-6 flex flex-col justify-center pl-2 text-casa-red-dark">Most Availability</div>
        </div>
        <div class="flex py-1">
          <div class="h-6 w-6 bg-casa-brown"></div><div class="h-6 flex flex-col justify-center pl-2 text-casa-red-dark">Some Availability</div>
        </div>
        <div class="flex py-1">
          <div class="h-6 w-6 bg-casa-brown-light"></div><div class="h-6 flex flex-col justify-center pl-2 text-casa-red-dark">Limited Availability</div>
        </div>
        <div class="flex py-1">
          <div class="h-6 w-6 bg-gray-300"></div><div class="h-6 flex flex-col justify-center pl-2 text-casa-red-dark">Low Availability</div>
        </div>
      </div>

      <div class="w-full flex py-4">
        <CalendarWidget class="mx-auto w-full" inline v-model="date" @date-select="onDateSelect" v-if="calendarDataLoaded" :disabled-dates="disabledDates" :min-date="minDate" :max-date="maxDate">
          <template #date="slotProps">
            <div class="flex items-center justify-center mx-auto w-full h-full date" :class="getAvailabilityClass(slotProps.date)">{{ slotProps.date.day }}</div>
          </template>
        </CalendarWidget>
      </div>

      <SelectButton v-if="showMealSelector" v-model="meal" @change="onMealSelect" class="capitalize pt-2 w-full flex" :options="['lunch', 'dinner']" aria-labelledby="basic">
        <template #option="slotProps">
          <img v-if="slotProps.option == meal" class="w-7 mr-2 -ml-9" src="@/assets/images/flower-beige.png"/>
          {{ slotProps.option }}
        </template>
      </SelectButton>

      <p v-if="errors['search']" class="text-lg text-casa-danger text-center mt-4">{{ errors['search'] }}</p>

      <p v-else-if="showMealSelector && !searchDataLoaded && !loading" class="py-4 text-casa-red-dark text-center">
        Select a meal to view available times
      </p>
      <div v-else-if="searchDataLoaded && noAvailabilityReasons.length" ref="searchDataHeader">
        <p v-for="reason in noAvailabilityReasons" class="text-casa-danger py-4 text-lg text-center" :key="reason">{{ getDisplayNoAvailabilityReasons(reason) }}</p>
      </div>
      <p v-else-if="searchDataLoaded && !noAvailabilityReasons.length" class="py-4 text-casa-red font-serif text-lg" ref="searchDataHeader">
        Available tickets for {{ displayDate }} <span class="whitespace-nowrap">({{ numAvailableTableTypes }} option{{ numAvailableTableTypes > 1 ? 's' : '' }}):</span>
      </p>

      <p v-if="errors['hold']" class="text-lg text-casa-danger text-center my-4">{{ errors['hold'] }}</p>

      <div v-if="searchDataLoaded">
        <div v-for="(table_type_data, table_type, idx) in availableTimesByTableType" :key="table_type" :data-tt-idx="idx">
          <div class="flex justify-between items-end">
            <h2 class="text-xl text-casa-red-dark font-semibold">{{ table_type_data.item.name }}</h2>
          </div>
          <p class="text-casa-red-dark py-2">{{ table_type_data.item.description }}</p>
          <!-- times -->
          <div class="grid grid-cols-3 md:grid-cols-4 gap-3 gap-y-4 py-2 pb-10">
            <button v-for="time_obj in table_type_data.times" :key="time_obj.time" @click="onTimeSelect(table_type, time_obj.time, time_obj.booking_experience)" type="button" class="bg-casa-red hover:bg-casa-red-light text-white text-center font-serif h-10 flex justify-center items-center rounded-md cursor-pointer">
              {{ getDisplayTime(time_obj.time) }}
            </button>
          </div>
        </div>
      </div>

      </div> <!-- end if (calendarDataLoaded) -->

    </div>
  </main>
</template>

<script>
  import api from "@/api"

  export default {
    data() {
      return {
        loading: true,
        calendarDataLoaded: false,
        searchDataLoaded: false,
        errors: {},
        calloutText: '',
        bookingId: this.$route.query.booking_id || '',
        flexToken: this.$route.query.flex_token || '',
        numAdults: 0,
        numKids: 0,
        numBabies: 0,
        availabilityCalendar: {},
        date: null,
        meal: '',
        showMealSelector: false,
        itemsByTableType: {},
        timesByTableType: {},
        hasScrolledToSearch: false,
        noAvailabilityReasons: [],
        systemStatusMessages: [],
      }
    },
    created() {
      this.loadCalendarData()
    },
    mounted() {
      this.$plausible.trackPageview()
    },
    computed: {
      advisoryMessage() {
        if (this.systemStatusMessages && this.systemStatusMessages.length) {
          let advisoryMessages = this.systemStatusMessages.filter(m => m.message_type == 'advisory')
          if (advisoryMessages.length) {
            return advisoryMessages[0]
          }
        }
        return null
      },
      blockingMessage() {
        if (this.systemStatusMessages && this.systemStatusMessages.length) {
          let blockingMessages = this.systemStatusMessages.filter(m => m.message_type == 'blocking')
          if (blockingMessages.length) {
            return blockingMessages[0]
          }
        }
        return null
      },
      disabledDates() {
        const closedDates = Object.entries(this.availabilityCalendar)
        .filter(([dateKey, value]) => value == 'closed')
        .map(([dateKey]) => new Date(dateKey.replaceAll('-', '/')))

        return closedDates
      },
      minDate() {
        const dates = Object.keys(this.availabilityCalendar)
        if (! dates.length) {
          return null
        }
        dates.sort()
        return this.formatYYYYMMDDToDate(dates[0])
      },
      maxDate() {
        const dates = Object.keys(this.availabilityCalendar)
        if (! dates.length) {
          return null
        }
        dates.sort()
        return this.formatYYYYMMDDToDate(dates[dates.length - 1])
      },
      dateStr() {
        let yyyy = this.date.getFullYear().toString();
        let mm = (this.date.getMonth() + 1).toString();
        let dd = this.date.getDate().toString();
        let date_str = yyyy + '-' + mm.padStart(2, '0') + '-' + dd.padStart(2, '0')
        return date_str;
      },
      displayDate() {
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        return this.date.toLocaleDateString(undefined, options)
      },
      partySize() {
        return this.numAdults + this.numKids + this.numBabies
      },
      availableTimesByTableType() {
        let ret = {}

        if (this.searchDataLoaded) {
          for (const table_type of Object.keys(this.timesByTableType)) {
            if (this.timesByTableType[table_type].length) {
              ret[table_type] = {
                'item': this.itemsByTableType[table_type],
                'times': this.timesByTableType[table_type]
              }
            }
          }
        }

        return ret
      },
      numAvailableTableTypes() {
        return Object.keys(this.availableTimesByTableType).length
      }
    },
    methods: {
      clearErrors() {
        this.errors = {}
        this.noAvailabilityReasons = []
      },
      async loadCalendarData() {
        this.clearErrors()
        try {
          let resp = await api.get(`/flex-calendar?booking_id=${this.bookingId}&flex_token=${this.flexToken}`)
          if (resp.error) {
            this.errors['calendar'] = resp.error
          } else {
            this.availabilityCalendar = resp.availability_calendar
            this.booking = resp.booking
            this.numAdults = resp.booking.num_adults
            this.numKids = resp.booking.num_kids
            this.numBabies = resp.booking.num_babies
            this.calendarDataLoaded = true
          }
          this.loading = false
          // this.$plausible.trackEvent('FlexTickets-PageLoaded')
        } catch (error) {
          this.loading = false
          if (error.status == 'error') {
            console.error(error.display_error)
            this.errors['calendar'] = error.display_error
          } else {
            console.error(error)
          }
        }
      },
      scrollToRef(refName) {
        let element = this.$refs[refName]
        element.scrollIntoView({ behavior: "smooth" })
      },
      getAvailabilityClass(date) { // NB: not a JS Date object, rather slotProps.date from CalendarComponent
        if (!date || date.otherMonth) {
          return ''
        }
        let dateStr = `${date.year}-${(date.month+1).toString().padStart(2, '0')}-${(date.day).toString().padStart(2, '0')}`
        let dateData = this.availabilityCalendar[dateStr]
        if (dateData) {
          if (dateData == 'closed') {
            return 'availability-closed'
          }
          let status = this.availabilityCalendar[dateStr]['status']
          if (status) {
            return `availability-${status}`
          }
        }
        return ''
      },
      onDateSelect() {
        if (this.availabilityCalendar[this.dateStr]) {
          let hasLunch = this.availabilityCalendar[this.dateStr]['has_lunch']
          let hasDinner = this.availabilityCalendar[this.dateStr]['has_dinner']
          if (hasLunch && !hasDinner) {
            this.meal = 'lunch'
            this.showMealSelector = false
          } else if (!hasLunch && hasDinner) {
            this.meal = 'dinner'
            this.showMealSelector = false
          } else if (hasLunch && hasDinner) {
            this.meal = ''
            this.showMealSelector = true
          }
        }
        this.updateSearch()
      },
      onMealSelect() {
        this.updateSearch()
      },
      async onTimeSelect(table_type, time, exp_id) {
        this.loading = true
        this.clearErrors()
        let holdData = {
          booking_id: this.bookingId,
          flex_token: this.flexToken,
          table_type: table_type,
          experience_id: exp_id,
          res_datetime: time
        }
        // this.$plausible.trackEvent('FlexHold-Start')
        try {
          let resp = await api.post('/flex-hold', holdData)
          if (resp.error) {
            this.errors['hold'] = resp.error
            this.loading = false
          } else {
            let reservationToken = resp['reservation_token']
            // this.$plausible.trackEvent('FlexHold-Held')
            this.$router.push({ path: '/flex-checkout', query: { booking_id: this.bookingId, flex_token: this.flexToken, reservation_token: reservationToken } })
          }
        } catch (error) {
          this.loading = false
          if (error.status == 'error') {
            console.error(error.display_error)
            this.errors['hold'] = error.display_error
          } else {
            console.error(error)
          }
        }
      },
      updateSearch() {
        if ((this.numAdults || this.numKids || this.numBabies) && this.date && this.meal) {
          this.doSearch()
        } else {
          this.itemsByTableType = {}
          this.timesByTableType = {}
          this.searchDataLoaded = false
        }
      },
      async doSearch() {
        this.loading = true
        this.clearErrors()
        // this.$plausible.trackEvent('FlexSearch')
        try {
          let resp = await api.get(`/flex-search?booking_id=${this.bookingId}&flex_token=${this.flexToken}&res_date=${this.dateStr}&service=${this.meal}`)
          if (resp.error) {
            this.errors['search'] = resp.error
            this.itemsByTableType = {}
            this.timesByTableType = {}
            this.searchDataLoaded = false
            this.loading = false
          } else {
            this.itemsByTableType = resp.items_by_table_type
            this.timesByTableType = resp.times_by_table_type
            if (resp.no_availability_reasons) {
              this.noAvailabilityReasons = resp.no_availability_reasons
            }
            this.searchDataLoaded = true
            this.loading = false
            if (!this.hasScrolledToSearch) {
              this.$nextTick(() => {
                this.scrollToRef('searchDataHeader')
                this.hasScrolledToSearch = true
              })
            }
          }
        } catch (error) {
          this.itemsByTableType = {}
          this.timesByTableType = {}
          this.searchDataLoaded = false
          this.loading = false
          if (error.status == 'error') {
            console.error(error.display_error)
            this.errors['search'] = error.display_error
          } else {
            console.error(error)
          }
        }
      },
      getDisplayTime(time) {
        let t = time.split('T')[1]
        let [hours, minutes] = t.split(':')
        const date = new Date()
        date.setHours(hours)
        date.setMinutes(minutes)
        const ampmTime = date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })

        return ampmTime
      },
      getDisplayNoAvailabilityReasons(reason) {
        return reason
      },
      formatYYYYMMDDToDate(d_str) {
        d_str = d_str.replaceAll('-', '');
        let yyyy = parseInt(d_str.substring(0, 4), 10);
        let mm = parseInt(d_str.substring(4, 6), 10) - 1;
        let dd = parseInt(d_str.substring(6, 8), 10);

        return new Date(yyyy, mm, dd);
      }
    }
  }
</script>

<style scoped>
  .date.availability-none {
    @apply bg-gray-300 text-casa-brown-dark
  }
  .date.availability-low {
    @apply bg-casa-brown-light text-white
  }
  .date.availability-medium {
    @apply bg-casa-brown text-white
  }
  .date.availability-high {
    @apply bg-casa-brown-dark text-white
  }
  .dayLabel-selected .date {
    /* border: 2px solid white; */
    @apply border-2 border-white
  }
  .dayLabel-enabled .date:hover {
    /* border: 2px solid white; */
    @apply border-2 border-white/80
  }

  select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23AF4537' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    /* for Safari */
    text-align-last: center;
  }
</style>
<template>
  <main>
    <div class="mx-auto max-w-2xl px-6 sm:px-8 py-4">

      <span v-if="loading" class="loading-bg"></span>
      <span v-if="loading" class="loader"></span>

      <DiaLog v-if="blockingMessage" :visible="true" modal :header="blockingMessage.title" :draggable="false" :closable="false">
          <p v-html="blockingMessage.message"></p>
      </DiaLog>

      <p v-if="advisoryMessage" class="mt-4 advisory-message" v-html="advisoryMessage.message"></p>

      <p v-if="errors['start']" class="mt-2 mb-2 html-error" v-html="errors['start']"></p>

      <div v-if="startDataLoaded">

        <template v-if="step == 'flex-validate'">
          <p class="text-center text-casa-red-dark pb-4">Enter the ticket confirmation # and email associated with your booking.</p>

          <label for="booking_id" class="block mt-2 mb-1 text-casa-brown text-sm">Confirmation #</label>
          <InputText id="booking_id" type="text" v-model="bookingId" class="w-full mb-1"/>

          <label for="email" class="block mt-2 mb-1 text-casa-brown text-sm">Email</label>
          <InputText id="email" type="text" v-model="email" @keyup.enter="flexValidate" class="w-full mb-1" />

          <p v-if="errors['flex_validate']" class="mt-4 mb-2 text-center text-casa-danger">{{ errors['flex_validate'] }}</p>

          <div class="mx-auto flex align-center justify-center pt-4">
            <button type="submit" id="submit" @click.prevent="flexValidate" class="mx-auto bg-casa-red hover:bg-casa-red-light text-white uppercase font-serif py-3 px-8 rounded">
              Submit
            </button>
          </div>
        </template>

        <template v-if="step == 'flex-validate-confirm'">
          <p class="text-center text-casa-red-dark pb-4">We've sent you an email with a verification code - please enter it below.</p>

          <div class="text-center mb-2">
            <label for="code" class="block mt-2 mb-1 text-casa-brown text-sm">Verification Code</label>
            <InputText id="code" type="text" v-model="code" @keyup.enter="flexValidateConfirm" class="mb-1"/>
          </div>

          <p v-if="errors['flex_validate_confirm']" class="mt-4 mb-2 text-center text-casa-danger">{{ errors['flex_validate_confirm'] }}</p>

          <div class="mx-auto flex align-center justify-center pt-4">
            <button type="submit" id="submit" @click.prevent="flexValidateConfirm" class="mx-auto bg-casa-red hover:bg-casa-red-light text-white uppercase font-serif py-3 px-8 rounded">
              Submit
            </button>
          </div>
        </template>

      </div>

    </div>
  </main>
</template>

<script>
  import api from "@/api"

  export default {
    data() {
      return {
        loading: true,
        startDataLoaded: false,
        step: 'flex-validate',
        errors: {},
        systemStatusMessages: [],
        bookingId: this.$route.query.booking_id || '',
        email: this.$route.query.email || '',
        code: ''
      }
    },
    created() {
      this.loadStartData()
    },
    mounted() {
      this.$plausible.trackPageview()
    },
    computed: {
      advisoryMessage() {
        if (this.systemStatusMessages && this.systemStatusMessages.length) {
          let advisoryMessages = this.systemStatusMessages.filter(m => m.message_type == 'advisory')
          if (advisoryMessages.length) {
            return advisoryMessages[0]
          }
        }
        return null
      },
      blockingMessage() {
        if (this.systemStatusMessages && this.systemStatusMessages.length) {
          let blockingMessages = this.systemStatusMessages.filter(m => m.message_type == 'blocking')
          if (blockingMessages.length) {
            return blockingMessages[0]
          }
        }
        return null
      },
    },
    methods: {
      clearErrors() {
        this.errors = {}
      },
      async loadStartData() {
        this.clearErrors()
        try {
          let resp = await api.get(`/flex-start`)
          this.systemStatusMessages = resp.system_status_messages
          this.startDataLoaded = true
          this.loading = false
          // this.$plausible.trackEvent('FlexStart-PageLoaded')
        } catch (error) {
          this.loading = false
          if (error.status == 'error') {
            console.error(error.display_error)
            this.errors['start'] = error.display_error
          } else {
            console.error(error)
          }
        }
      },
      async flexValidate() {
        this.clearErrors()
        this.loading = true
        try {
          let resp = await api.post(`/flex-validate`, {booking_id: this.bookingId, email: this.email} )
          this.errors['flex_validate'] = resp.error
          if (resp.eligible && !resp.error) {
            this.step = 'flex-validate-confirm'
            // this.$plausible.trackEvent('FlexStart-Validated')
          }
          this.loading = false
        } catch (error) {
          this.loading = false
          if (error.status == 'error') {
            console.error(error.display_error)
            this.errors['flex_validate'] = error.display_error
          } else {
            console.error(error)
          }
        }
      },
      async flexValidateConfirm() {
        this.clearErrors()
        this.loading = true
        try {
          let resp = await api.post(`/flex-validate-confirm`, {booking_id: this.bookingId, code: this.code} )
          this.errors['flex_validate_confirm'] = resp.error
          if (resp.flex_session_token && resp.booking && !resp.error) {
            // this.$plausible.trackEvent('FlexStart-ValidatedConfirmed')
            this.$router.push({ path: '/flex-tickets', query: { booking_id: resp.booking.id, flex_token: resp.flex_session_token } })
          }
          this.loading = false
        } catch (error) {
          this.loading = false
          if (error.status == 'error') {
            console.error(error.display_error)
            this.errors['flex_validate_confirm'] = error.display_error
          } else {
            console.error(error)
          }
        }
      },
    }
  }
</script>
import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext"
import SelectButton from "primevue/selectbutton";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog"
import PrimeVuePreset from './presets/primevue-preset';
import plausiblePlugin from './plugins/plausiblePlugin'

import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue"

const app = createApp(App)

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            // new Sentry.BrowserTracing({
            //     tracePropagationTargets: [import.meta.env.VITE_API_BASE],
            //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            // }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],

        // Performance Monitoring
        // tracesSampleRate: 1.0, //  Capture 100% of the transactions

        // Session Replay
        replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

app.use(createPinia())
app.use(router)
app.use(plausiblePlugin)
app.component("CalendarWidget", Calendar);
app.component("CheckBox", Checkbox);
app.component("InputText", InputText);
app.component("SelectButton", SelectButton);
app.component("DiaLog", Dialog);
app.use(PrimeVue, {
    unstyled: true,
    pt: PrimeVuePreset
})

app.mount('#app')

<script setup>
</script>

<template>
  <main>
    <div class="mx-auto max-w-2xl px-6 sm:px-8 py-4">

      <span v-if="loading" class="loading-bg"></span>
      <span v-if="loading" class="loader"></span>

      <div v-if="confirmDataLoaded">

      <h2 class="text-lg font-semibold text-casa-red pb-2">
        You're going to Casa Bonita!
      </h2>

      <h2 class="text-lg font-semibold text-casa-red py-3">Confirmation #{{ confirmData['id'] }} </h2>

      <div class="px-6 py-4 mt-2 w-full h-full border-casa-red border rounded-md">
        <h2 class="font-semibold text-lg text-casa-brown">Ticket Summary - ${{ confirmData.amount_total }}</h2>
        <div class="font-medium text-casa-brown pt-2 pb-4">
          <div class="py-2 flex">
            <div class="w-auto h-6 pr-4 text-base">
              <i class="fa-sharp fa-light fa-user"></i>
            </div>
            <div>
                <span>{{ confirmData['num_adults'] }} Adults</span>
                <span v-if="confirmData['num_kids'] > 0">, {{ confirmData['num_kids'] }} Kids (3-12)</span>
                <span v-if="confirmData['num_babies'] > 0">, {{ confirmData['num_babies'] }} Kids (0-2)</span>
              </div>
          </div>
          <div class="py-1 flex">
            <div class="w-auto h-6 pr-4 text-base">
              <i class="fa-sharp fa-light fa-calendar-day"></i>
            </div>
            <div>{{ getDisplayDate(confirmData['datetime']) }}</div>
          </div>
          <div class="py-1 flex">
            <div class="w-auto h-6 pr-4 text-base">
              <i class="fa-sharp fa-light fa-clock"></i>
            </div>
            <div>{{ getDisplayTime(confirmData['time']) }}</div>
          </div>
          <div class="py-1 flex">
            <div class="w-auto h-6 pr-4 text-base">
              <i class="fa-sharp fa-light fa-table-picnic"></i>
            </div>
            <div>{{ confirmData['experience_name'] }}</div>
          </div>
          <div class="py-1 flex">
            <div class="w-auto h-6 pr-4 text-base">
              <i class="fa-sharp fa-light fa-map-location-dot"></i>
            </div>
            <div>6715 W Colfax Ave, Lakewood, CO 80214</div>
          </div>
          <div class="py-1 flex" v-if="confirmData['is_flex']">
            <div class="w-auto h-6 pr-4 text-base">
              <i class="fa-sharp fa-light fa-ticket"></i>
            </div>
            <div>FLEX Ticket</div>
          </div>
        </div>
        <h2 class="font-semibold text-casa-brown text-xl">A note from the restaurant</h2>
        <p class="text-casa-brown py-1">
          Your ticket and order confirmation have been delivered to your email. Your ticket can be printed or displayed on a mobile device for check-in at the restaurant.
        </p>
        <p class="text-casa-brown py-1">
          All tickets are final sale, non-refundable, and non-transferable.
        </p>
        <p class="text-casa-brown py-1">
          If you have questions about your order, please reach out to {{ (confirmData['is_flex'] ? 'flex' : 'tickets') }}@casabonitadenver.com
        </p>
        <p class="text-casa-brown py-1">
          We're excited to see you soon at Casa Bonita!
        </p>
      </div>

      </div>

      <div v-else-if="confirmDataError">
        <p class="text-center">{{ confirmDataError }}</p>
      </div>
    </div>
  </main>
</template>

<script>
  import api from "@/api"

  export default {
    data() {
      return {
        loading: true,
        confirmData: {},
        confirmDataLoaded: false,
        confirmDataError: ''
      }
    },
    created() {
    },
    mounted() {
      this.loadConfirmation()
      this.$plausible.trackPageview()
    },
    methods: {
      async loadConfirmation() {
        try {
          let resp = await api.post('/get-confirmation', {booking_id: this.$route.query.booking_id, reservation_token: this.$route.query.reservation_token})
          this.loading = false
          if (resp.status == 'OK') {
            this.confirmData = resp
            this.confirmDataLoaded = true
          } else {
            console.error("/get-confirmation did not return with status 'OK'")
          }
        } catch (error) {
          this.loading = false
          if (error.status == 'error') {
            console.error(error.display_error)
            this.confirmDataError = error.display_error
          } else {
            console.error(error)
          }
        }
      },
      getDisplayDate(isoDateString) {
        let date = new Date(isoDateString)
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        return date.toLocaleDateString(undefined, options)
      },
      getDisplayTime(time) {
        let [hours, minutes] = time.split(':')
        let date = new Date()
        date.setHours(hours)
        date.setMinutes(minutes)
        return date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })
      },
    }
  }
</script>

<style scoped>
</style>
